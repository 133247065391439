@import url(https://fonts.googleapis.com/css?family=Rubik);
body {
  margin: 0;
  font-family: 'Rubik';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
    width: 100%;
    height: 100vh;
    background: #fff;
    display: block;
    background-image: url(/static/media/background.e1f19943.png);
    background-position: center -9rem;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 110px;
}

.main-logo {
    max-width: 45rem;
    margin: auto;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 10px;
}

.card {
    max-width: 45rem;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 30px 40px -20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: #fff;
    text-align: center;
    padding-top: 3vw;
    padding-bottom: 70px;
    margin: auto; 
}

h1 {
    font-style: normal;
    color: #0C265D;
    font-size: 40px;
}

p {
    color: #FF7700;
    font-size: 20px;
}

.main-button {
    margin: auto;
    max-width: 45rem;
    text-align: center;
    padding-top: 4rem;
}

button {
    background: linear-gradient(180deg, #FF7700 0%, #FF9900 100%);
    box-shadow: 0px 30px 40px -20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bolder;
    width: 300px;
    height: 60px;
    cursor: pointer;
}


